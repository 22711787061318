.head-page {

	margin: 40px 0 40px;
    text-align: center;
    
    &.head-page--margin-top {
        margin: 116px 0 72px;
    }

	@media #{$smWidth} {

		margin: 20px 0;

	}

	&__title{
		color: #fff;
	}

	&__intro {

		font-size: 20px;
		color: rgba(255,255,255,.7);
		margin: 22px 0;

		@media #{$smWidth} {

			font-size: 16px;
			margin: 16px 0;

		}

	}

	&__btn {

		display: flex;
		justify-content: center;

	}

	&__audited {
		margin-top: 8px;
		height: 64px;
		line-height: 64px;
		border-bottom: 1px solid #fff;
		&:hover{
			color: var(--yellow);
			border-bottom: 1px solid var(--yellow);
		}
	}

}

.arrow-back {
	width: 1200px;
	text-align: left;
    
    a {
        display: inline-flex;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
		cursor: pointer;
		color: #fff;
    }

    svg {
        margin-right: 4px;
		width: 24px;
		height: fit-content;
    }
}