.footer {
	width: 100%;
	flex-shrink: 0;
	background-color: rgba(255,255,255,.05);
	padding: 12px;
	display: flex;
	justify-content: center;
	margin-top: -80px;
	height: 80px;
	z-index: 9999;
	clear: both;

	&__links  {

		display: flex;
		justify-content: center;

		li {

			margin: 12px;

		}

		a {

			display: block;
			width: 30px;
			height: 30px;

			@media #{$desktop} {

				&:hover svg {

					fill: var(--yellow);

				}

			}

		}

	}

}