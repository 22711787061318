header {
    position: fixed !important;
    width: 100%;
}

#aboutTextWrapper {
    color: #fff;
    font-family: 'Nunito Sans';
    font-size: 22px;
}
