.artist{
    h1{
        font-size: 30px;
        margin-top: 40px;
        margin-bottom: 20px;
        color: #000;
    }
    &_list{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        grid-gap: 16px;
        justify-content: center;
        margin: 30px 0 80px;
    }
    &_item{
        box-shadow: 0px 4px 34px rgba(31, 31, 31, 0.13);
        border-radius: 25px;
        color: var(--bg);
        text-align: center;
        overflow: hidden;
        padding: 40px;
        @media #{$smWidth} {
            width: 100%;
        }
        .image{
            overflow: hidden;
            width: 160px;
            height: 160px;
            display: block;
            margin: 0 auto;
            border-radius: 50%;
            img{
                width: 100%;
            }
        }
        .content{
            padding-top: 20px;
            .title{
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                color: #000;
            }
            p{
                color: #000;
                margin-top: 20px;
            }
        }
    }
    &_detail{
        .info{
            background: url('../img//artist/ProfileBanner.png') no-repeat;
            background-size: cover;
            padding: 50px;
            height: 300px;
            display: flex;
            align-items: flex-end;
            flex-direction: row;
            justify-content: space-between;
            color: #fff;
            width: 100%;
            @media #{$smWidth} {
                padding: 20px;
                flex-direction: column;
            }
            .img{
                width: 148px;
                height: 148px;
                border-radius: 50%;
                overflow: hidden;
                background:#888;
                @media #{$smWidth} {
                    width: 70px;
                    height: 70px;
                }
                img{
                    width: 100%;
                }
            }
            .address{
                display: inline-block;
                padding: 0 10px;
                background: linear-gradient(97.86deg, #5721B1 8.15%, #78D0E5 93.61%);
                border-radius: 25px;
                height: 25px;
                line-height: 25px;
                font-size: 14px;
                text-align: center;
                color: #fff;
            }
            .media{
                display: flex;
                margin-top: 10px;
                justify-content: space-between;
                flex-direction: row;
                &:nth-last-of-type(2){
                    width: 100px;
                }
                img{
                    margin: auto;
                    width: 100%;
                }
            }
            .name{
                height: 148px;
                padding: 10px 20px;
                h2{color:#fff;   
                    @media #{$smWidth} {
                    font-size:18px;
                    }
                
                }
                width: calc(100% - 150px);
                @media #{$smWidth} {
                    width: calc(100% - 70px);
                }
            }
            .btn{
                background: #FFFFFF;
                border-radius: 25px;
                height: 40px;
                width: 114px;
                span{
                    background: linear-gradient(91.54deg, #5721B1 5.52%, #78D0E5 86.82%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            &>div{
                &:first-of-type{
                    display: flex;
                    flex-direction: row;
                }
                &:last-of-type{
                    width: 280px;
                    font-size: 14px;
                }
            }
        }
    }
    .ant-tabs-nav-list{
        .ant-tabs-tab, .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
            color: #000;
            font-size:18px;
        }
        .ant-tabs-ink-bar{
            background: linear-gradient(89.99deg, #5721B1 -2.81%, #78D0E5 93.2%);
        }
    }
}
#artist-profile{
    .ant-form{
        p{
            color: #888;
            text-align: center;
        }
        .ant-upload.ant-upload-select-picture-card{
            border: 1px solid #d9d9d9;
            border-radius: 50%;
        }
        &-item-label{
            background:#f1f1f1;
            height: 40px;
            line-height: 40px;
            padding:0 15px;
            border-top-left-radius: 25px;
            border-bottom-left-radius: 25px;
            box-shadow: 3px 3px 5px rgba(229, 229, 229, 0.5);
            width: 120px;
            text-align: left;
        }
        // &-item{
        //     box-shadow: 3px 3px 5px rgba(229, 229, 229, 0.5);
        // }
        &-item-control-input{
            .name-input{
                margin-left: 0!important;
                width: 100%!important;
            }
            .ant-input{
                height: 40px;
                box-shadow: 3px 3px 5px rgba(229, 229, 229, 0.5);
                line-height: 40px;
                border: 1px solid #F1F1F1;
                border-radius: 25px;
                background:#fff;
                width: calc(100% + 20px);
                margin-left: -20px;
            }
            .about-textarea{
                height: auto;
                margin-left: 0!important;
                width: 100%!important;
            }
        }
    }
}