.home{
    h1{
        font-size: 30px;
        margin-top: 40px;
        margin-bottom: 20px;
        color: #000;
        .link{
            float: right;
            color: #888;
            font-size: 18px;
            font-weight: normal;
        }
    }
    #banner{
        width: 100%;
        height: 300px;
        overflow: hidden;
    }
    .title{
        text-align: center;
        margin-bottom: 40px;
        padding: 0 20px;
        img{
            display: inline-block;
            position: relative;
            top: 10px;
        }
    }
    .img{
        margin: 40px auto;
        @media #{$smWidth} {
            width: 100%;
        }
    }
}