.loader-container {
    padding-top: 70px;
    text-align: center;
    display: flex;
    background-image: url('../../../src/assets/img/landing-page/bg.png');
    background-repeat:no-repeat;
    background-size:100% 100%;
}

.landing-rectangle {
    background-color: rgba(255, 255, 255 ,0);
    width: 25%;
    height: calc(100vh - 0px);
    margin-right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing-rectangle:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.logo-wrapper {
    width: 250px;
    height: 175px;
}

.textWrapper {
    font-size: 40px;
    color: #fff;
    font-family: 'Nunito Sans';
}

.textWrapperDark {
    font-size: 40px;
    color: #5721B1;
    font-family: 'Nunito Sans';
}

.landing-rectangle svg {
    width: 75%;
    height: 55px;
}

.landing-rectangle rect {
    width: 200px;
    height: 55px;
}

.logo {
    display: inline-block;
    width: 80%;
}

.gamelogo {
    display: inline-block;
    width: 100%;
}

.enter-wrapper {
    margin-top: 50px;
}

.enterkey {
    display: inline-block;
    width: 50%;
}

.enterKeyTextWrapper {
    display: flex;
    position: relative;
    font-size: 20px;
    justify-content: center;
    bottom: 44px;
}

.comingSoonTextWrapper {
    position: relative;
    font-size: 22px;
}

@media(max-width: 640px) {
    .loader-container {
        flex-direction: column;
        height: calc(100vh);
    }
    .landing-rectangle {
        width: 100%;
    }
}