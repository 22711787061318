.exhibition-hall {
    width: 100%;
    display: flex;
    &-center{
        padding: 80px 200px;
        min-width: 873px;
    }
    &-head {
        width: 100%;
        .btn{
            border: 1px solid var(--primary);
            width: auto;
            padding: 5px 10px;
            height: 30px;
            border-radius: 25px;
            line-height: 1;
            float: right;
            margin-top: 7px;
            &.disabled{
                border: 1px solid #888;
                color: #888;
            }
        }
    }
    &-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        grid-gap: 16px;
        justify-content: center;
        margin: 30px 0 80px;
        .loading{
            width: 100%;
            text-align: center;
        }
        .ant-card{
            border: 1px solid #F1F1F1;
            border-radius: 25px;
            box-shadow: 5px 5px 5px rgba(229, 229, 229, 0.5); 
            border-radius: 25px;
            text-align: center;
            overflow: hidden;
            @media #{$smWidth} {
                width: 100%;
            }
    
            &-head{
                border:none;
                padding: 0 25px;
            }
            &-body{
                padding: 0;
                .image{
                    width: 100%;
                    padding: 0 25px;
                    img{
                        width: 100%;
                    }
                }
            }
            .artist-info{
                text-align: left;
                img{
                    width: 35px;
                    height: 35px;
                    background: #888;
                    border-radius: 50%;
                    display: inline-block;
                }
                span{
                    margin-left: 10px;
                }
            }
            .no-auction{
                text-align: center;
                padding: 20px 0;
                color: var(--primary);
                font-size: 18px;
                font-weight: 700;
            }
            .auction-info{
                padding: 15px 25px;
                &.live{
                    background: linear-gradient(126.98deg, #5721B1 35.51%, #78D0E5 96.66%);
                    .table{
                        th, td{
                            color: #fff;
                        }
                    }
                }
                &.closed{
                    .table{
                        th, td{
                            color: #000;
                        }
                    }  
                }
                .table{
                    width: 100%;
                    th{
                        font-weight: normal;
                        font-size: 12px;
                        &:first-of-type{
                            text-align: left;
                        }
                        &:last-of-type{
                            text-align: right;
                        }
                    }
                    td{
                        font-weight: bold;
                        font-size: 14px;
                        &:first-of-type{
                            text-align: left;
                        }
                        &:last-of-type{
                            text-align: right;
                        }
                    }
                }
            }
            .content{
                .title{
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    padding: 15px 25px;
                    text-align: left;
                    margin-bottom: 0;
                    &.active{
                        box-shadow: 0px 5px 5px rgba(229, 229, 229, 0.3);
                    }
                    span{
                        background: linear-gradient(91.54deg, #5721B1 5.52%, #78D0E5 86.82%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }

        &__item {
            box-shadow: 0px 4px 34px rgba(31, 31, 31, 0.13);
            border-radius: 25px;
            min-width: 280px;
            width: calc(25% - 20px);
            margin: 10px;
            color: var(--bg);
            text-align: center;
            overflow: hidden;


            @media #{$smWidth} {
                max-width: 100%;
                margin: 18px auto;
            }

            .item {
                &__tag{
                    background: linear-gradient(93.41deg, #5721B1 0%, #78D0E5 97.08%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    text-align: left;
                }
                &__image {
                    img {
                        width: 100%;
                        border-radius: 25px;
                        @media #{$smWidth} {
                            height: 100%;
                        }
                    }
                }

                &__title {
                    display: block;
                    color: #000;
                    text-align: left;
                    padding: 20px 20px 0;
                    font-size: 18px;

                    &:hover {
                        color: rgba(0, 0, 0, 0.7);
                    }
                }

                &__author {
                    font-size: 14px;
                    padding: 10px 20px;
                    background: linear-gradient(91.54deg, #5721B1 5.52%, #78D0E5 86.82%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    text-align: left;
                }

                &__hashtags {
                    max-width: 270px;
                    margin: 0 auto 16px;

                    &>span {
                        cursor: pointer;
                    }
                }

                &__aution {
                    padding: 30px 20px;
                    text-align: left;
                    color: rgba(0, 0, 0, 0.5);
                    .no_list{
                        color: var(--primary);
                        font-weight: 700;
                    }
                    &.hasAuction{
                        color: #fff;
                        background: linear-gradient(154.22deg, #6E52D5 60.47%, #78D0E5 97%);
                    }
                    .aution{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                    }
                }
            }
        }
    }

    &-card {
        display: flex;
        width: 100%;
        background: #fff;
        border-radius: 12px;
        color: #000;
        justify-content: flex-start;
        margin-top: 20px;
        @media #{$smWidth} {
           flex-direction: column;
        }
        &__header {
            text-align: center;

            &__author {
                margin: 40px 0;
                font-size: 32px;
                font-weight: 400;
                border: 1px solid #F1F1F1;
                box-sizing: border-box;
                padding: 15px 40px;
                border-radius: 30px;
                span{
                    background: linear-gradient(90.74deg, #5721B1 9.5%, #78D0E5 64.36%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                @media #{$smWidth} {
                    font-size: 18px;
                }
            }
        }

        &__left {
            text-align: center;
            width: 60%;
            @media #{$smWidth} {
                width: 100%;
            }
            img, video {
                height: 483px;
                max-width: 100%;
                margin: 0 auto;
                cursor: pointer;
                background: #ddd;
                border-radius: 25px;
            }
        }

        &__right {
            padding-left:40px;
            width: 40%;
            // max-height: calc(100vh - 160px);
            // overflow-y: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media #{$smWidth} {
                width: 100%;
                padding: 0;
                margin-top: 20px;
            }
        }

        .owner{
            height: 85px;
            padding: 20px 40px;
            .info{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                color: #000;
                width: 100%;
                p{margin-bottom: 0;}
                h2{
                    margin-bottom: 0;
                }
                .img{
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    overflow: hidden;
                    background: #888;
                    img{
                        width: 100%;
                    }
                }
                .name{
                    padding: 0px 10px;
                }
                &>div{
                    &:first-of-type{
                        display: flex;
                        flex-direction: row;
                    }
                    &:last-of-type{
                        margin-top: 10px;
                        height: 23px;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        font-size: 14px;
                    }
                }
                .ant-tag{
                    background: #fff;
                    border: 1px solid #F1F1F1;
                    box-shadow: 3px 3px 4px rgba(229, 229, 229, 0.5);
                    border-radius: 10px
                }
            }
        }
        .auction{
            height: 370px;
            padding: 40px;
            position: relative;
            h3{
                text-align: center;
            }
            .not_sale{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
            }
            .btn{
                box-shadow: 3px 3px 4px rgba(229, 229, 229, 0.5);
                border-radius: 25px;
                color: #fff;
                background: linear-gradient(94.56deg, #5721B1 11.19%, #78D0E5 86.35%);
                position: absolute;
                bottom: 40px;
                left: 50%;
                transform: translateX(-50%);
            }
            .table{
                width: 100%;
                th{
                    color: #888;
                    font-weight: normal;
                }
                td{
                    color: #000;
                    font-weight: bold;
                    font-size: 24px;
                }
            }
        }
        &__box{
            border: 1px solid #F1F1F1;
            box-sizing: border-box;
            box-shadow: 3px 3px 4px rgba(229, 229, 229, 0.5);
            border-radius: 25px;
            .title{
                text-align: left;
                padding: 20px 40px;
                border-top-left-radius: 25px;
                border-top-right-radius: 25px;
                box-shadow: 3px 3px 4px rgba(229, 229, 229, 0.5);
            }

        }
        .content{
            padding: 20px 40px;
            height: 250px;
            p {
                text-align: left;
            }
        }

        &__table {
            width: 100%;
            tr{
                padding: 15px;
                border-bottom: 1px solid #f1f1f1;
                &:last-of-type{
                    border: none;
                }
                td{
                    padding: 10px;
                    &:first-of-type{
                        padding-left: 10px;
                    }
                    &:last-of-type{
                        text-align: right;
                        font-weight: 700;
                    }
                }
            }
            td {
                word-break: break-word;
                &>a {
                    color: var(--bg);
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        .btn {
            max-width: 280px;
            margin: 0 auto;
        }
    }
}
.img-preview{
    width: 600px!important;
    @media #{$smWidth} {
        width: 100%!important;
    }
    img{
        width: 100%;
        height: auto;
    }
    .ant-modal-body{
        padding: 40px 20px!important;
    }
}