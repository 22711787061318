.menu {

	white-space: nowrap;

	&__list {

		display: flex;
		justify-content: center;
		color: #000;
		margin-bottom: 0;

		@media #{$mdWidth} {

			display: block;

		}

	}

	&__item {
		padding: 0 15px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		@media #{$mdWidth} {
			padding: 0  0 20px 0;
			height: auto;
			&:first-of-type{
				padding:20px 0 20px 0;
			}
		}

	}

	&__link {
		font-size: 15px;
		font-weight: 500;
		display: block;
        transform: var(--transitionDefault);
		cursor: pointer;
		color: inherit;

		&.is-current {  
			background: linear-gradient(93.41deg, #5721B1 0%, #78D0E5 97.08%);
			// background-image: -webkit-gradient(linear, 0 0, 0 bottom, from(rgba(0, 128, 0, 1)), to(rgba(51, 51, 51, 1)));
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

        &.is-current{
			color: var(--primary);
		}

		&.router-link-exact-active,
		&.router-link-active {
			color: var(--primary);
			pointer-events: none;
		}

		@media #{$desktop} {
			&:hover {
				color: color(var(--primary) a(50%));
			}
		}

		@media #{$mdWidth} {
			font-size: 20px;
			text-align: center;
			color: #000!important;
		}

	}

	&__link:hover{
		color: var(--primary);
	}

}