.billboard {
	color: #000;

	.center {
		width: 100%;
	}

	.btn{
		background: linear-gradient(95.74deg, #5721B1 36.75%, #78D0E5 90.46%);
		box-shadow: 5px 5px 4px #E5E5E5;
		border-radius: 10px;
		width: 200px;
		color: #fff;
		margin-top: 50px;
		display: inline-block;
		@media #{$smWidth} {
			width: 146px;
		}
	}

	&__box {
		position: relative;
		overflow: hidden;
		// display: flex;
		// align-items: center;
		// text-align: center;
		height: 820px;
		background: url('../img/home/header/bg.png') no-repeat;
		background-size: 100%;
		@media #{$mdWidth} {
			height: 700px;
		}
		@media #{$smWidth} {
			width: 100%;
			height: 628px;
			background-size: 100%;
			background: url('../img/home/header/bg-mobile.png') right no-repeat;
		}
	}

	&__content {

		position: absolute;
		left: 50%;
		top: 200px;
		transform: translateX(-50%);
		z-index: 1;
		width: 100%;
		max-width: 100%;
		padding: 40px 125px;
		@media #{$smWidth} {
			top: 10%;
			transform: translate(-50%, 0);
			padding: 40px;
		}
	}
	.btn-box{
		position: absolute;
		left: 125px;
		top: 350px;
		display: flex;
		width: 450px;
		justify-content: space-between;
		@media #{$smWidth} {
			width: 100%;
			left: 40px;
			flex-direction: column;
		}
	}

	&__title {
        font-weight: 800;
		font-size: 40px;
		@media #{$smWidth} {
			text-align: center;
			font-size: 26px;
			color: #fff;
		}

    }

    &__button {
        margin-top: 100px;
        display: inline-block;

        @media #{$mdWidth} {

            margin-top: 40px;
            font-size: 14px;

        }

        @media #{$smWidth} {

            margin-top: 15px;
            font-size: 12px;

        }
    }
    
    &__subtitle {
        display: inline-block;
        margin-top: 60px;

        @media #{$mdWidth} {

            margin-top: 30px;

        }

        @media #{$smWidth} {

            margin-top: 10px;
            font-size: 20px;
            line-height: 24px;

        }
    }

	&__img {

		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;

	}

}