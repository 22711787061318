.home-cards {

	margin: 20px 0 100px;

	@media #{$smWidth} {

		margin: 20px 0;

	}

	&__box {

		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		@media #{$smWidth} {

			display: block;

		}

	}

	&__item {

		padding: 40px;
		position: relative;
		border-radius: 20px;
		margin: 35px 80px;
		display: flex;
		flex-direction: column;
		box-shadow: 5px 5px 20px rgba(167, 167, 167, 0.25);
		&:nth-child(1){
			background: #E9FFFF;
		}
		&:nth-child(2){
			background: #EEE9FF;
		}
		&:nth-child(3){
			background: #E9F4FF;
		}
		&:nth-child(4){
			background: #E9F4FF;
		}
		&:nth-child(5){
			background: #E9FFFF;
		}
		.h3{font-size: 20px;}

		@media #{$mdWidth} {

			padding: 36px;
			width: 336px;
			text-align: center;

		}

		@media #{$smWidth} {

			max-width: 100%;
			margin: 18px auto;

		}

	}

	p {

		margin: 13px 0 0;
		img{
			margin: 0 auto;
			width: 80%;
		}

	}

	&__btn {

		margin-top: auto;

	}

	&__token {

		text-align: center;
		width: 336px;
		justify-content: center;
		align-items: center;

	}

	&__first-workshop {

		width: 708px;
		padding-left: 336px;

		@media #{$mdWidth} {

			justify-content: center;
			align-items: center;
			padding: 36px;
			width: 336px;

		}

	}

	&__img {

		position: absolute;
		top: 33px;
		left: 71px;

		@media #{$mdWidth} {

			display: none;

		}

	}

}