.form-app {
	width: 528px;
	max-width: 100%;
	color: #000;

	&__inner {

		background-color: #fff;
		border-radius: 12px;
		padding: 60px;
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);


		@media #{$smWidth} {

			padding: 20px;

		}

	}

	&__head {

		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 44px;

		@media #{$smWidth} {

			margin-bottom: 22px;

		}

	}

	&__head &__title {

		margin-bottom: 0;

	}

	&__title {

		font-weight: 400;
		text-align: center;
		margin-bottom: 22px;

	}

	&__btn-back {

		svg {

			fill: var(--bg);

		}

		@media #{$desktop} {

			&:hover svg {

				fill: color(var(--bg) a(50%));

			}

		}

	}

	&__inputbox {

		position: relative;
		margin: 20px 0;

		& + & {

			margin-top: 35px;

        }

        &-row {
            display: flex;
            align-items: center;
        }

		&--concatenation {

			padding-top: 1px;

			&::before {

				content: '+';
				font-size: 20px;
				position: absolute;
				top: -15px;
				left: 0;
				right: 0;
				text-align: center;

			}

		}

		&-head {

			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 13px 0;

		}

		&-control {

			position: relative;

		}

		&-after-text {

			font-size: 14px;
			line-height: 21px;
			opacity: .5;
			text-align: center;
			margin: 12px 0 24px;

		}

		&-up {

			position: absolute;
			top: 0;
			right: 22px;
			bottom: 0;
			display: flex;
			align-items: center;

			&-pref {
				cursor: pointer;
				font-weight: 600;
				font-size: 12px;
				line-height: 30px;
				padding: 0 12px;
				color: var(--bg);
				background-color: rgba(63, 147, 247, 0.5);
				border-radius: 6px;
				text-transform: uppercase;

			}

			&-logo {

				margin-left: 14px;

			}

			&-suf {

				margin-left: 14px;
				font-size: 14px;
				color: #000;
				min-width: 36px;

			}

		}

	}

	&__label {

		font-size: 14px;
		line-height: 21px;

		&:not(.link) {

			color: #000;

		}

	}

	&__note {

		font-size: 16px;
		line-height: 27px;
		color: #000;
		margin: 24px 0;
		padding-left: 12px;
		border-left: 4px solid var(--yellow);

	}

	&__border {

		padding: 20px;
		border-radius: 8px;
		border: 1px solid rgba(29,29,29,.2);

		@media #{$smWidth} {

			padding: 10px;

		}

	}

	&__total {

		display: flex;
		justify-content: space-between;

		@media #{$smWidth} {

			display: block;

		}

		&-item {

			padding: 0 8px;
			text-align: center;

			@media #{$smWidth} {

				display: flex;
				flex-direction: row-reverse;
				justify-content: space-between;

				& + & {

					margin-top: 12px;

				}

			}

		}

		&-value {

			font-weight: 600;
			font-size: 16px;
			line-height: 20px;
			display: block;

		}

		&-label {

			font-size: 14px;
			line-height: 20px;
			opacity: .5;
			display: block;
			margin-top: 3px;

		}

	}

	&__submit {

		margin-top: 24px;

		&--row {

			display: flex;
			justify-content: space-between;

			@media #{$smWidth} {

				display: block;

				.btn {

					max-width: 100%;
					margin-top: 12px;

				}

			}

			.btn {

				max-width: calc(50% - 12px);

			}

		}

		.btn {

			width: 100%;
			height: 60px;
			padding: 0;
            line-height: 60px;

            @media #{$smWidth} {
                max-width: 100%;
                margin-top: 12px;
            }

		}

	}

    &__close-btn {

		position: absolute;
		top: 24px;
		right: 24px;
		background: none;

		svg {

			fill: #000;

		}

	}

}

/* your-position */

.your-position {

	padding: 34px 60px;

	&__head {

		display: flex;
		align-items: center;
		justify-content: space-between;
		white-space: nowrap;
		color: #000;
		margin: 8px 0 10px;
		font-size: 20px;

		&-logo {

			flex-shrink: 0;

		}

		&-pair {

			flex-grow: 1;
			padding: 0 12px;

		}

		&-value {

			flex-shrink: 0;

		}

	}

	&__row {

		display: flex;
		justify-content: space-between;
		opacity: .5;
		color: var(--bg);
		margin-top: 6px;

	}

}

/* 	deposit */

.deposit {

	&__logo {

		display: flex;
		justify-content: center;
        margin-bottom: 8px;

        svg {
            margin: -10px 2px 26px;
        }

	}

	&__inputbox {

		margin: 36px 0 12px;

	}

}

/* slider */

.slider {

	&__head {

		display: flex;
		justify-content: space-between;

	}

	&__value {

		font-size: 60px;
		line-height: 72px;
		color: #000;
		margin: 24px 0;
		display: flex;

		&[data-suf]::after {

			content: attr(data-suf);

		}

	}

	&__track {

		height: 2px;
		margin-bottom: 35px;
		background-color: color(var(--bg) a(10%));

	}

	&__marker {

		display: flex;
		justify-content: space-between;

		&-item {

			font-weight: 600;
			font-size: 12px;
			line-height: 30px;
			width: 54px;
			cursor: pointer;
			background-color: color(var(--yellow) a(50%));
			border-radius: 6px;
			text-align: center;
			color: var(--bg);
			text-transform: uppercase;

		}

	}

	&__arrow {

		display: flex;
		justify-content: center;
		margin: 24px 0;

		svg {

			fill: #000;

		}

	}

	&__data {

		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		color: #000;

		& + & {

			margin-top: 6px;

		}

		&-pull-right {

			justify-content: flex-end;
			display: flex;
			margin: 12px 0 4px;

		}

		&-value {

			font-weight: 600;
			flex-shrink: 0;
			flex-grow: 1;
			padding: 0 3px;

		}

		&_logo {

			flex-shrink: 0;

		}

		&-suf {

			text-transform: uppercase;
			font-size: 20px;
			width: 50px;
			white-space: nowrap;
			padding-left: 12px;
			flex-shrink: 0;

		}

	}

	&__foot {

		margin: 24px 0 14px;

	}

}
