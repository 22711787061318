.home-service {

	margin: 42px 0;

	&__list {

		display: flex;
		margin: 20px 80px;
		justify-content: space-between;
		flex-wrap: wrap;

		@media #{$mdWidth} {
			flex-wrap: wrap;
			justify-content: center;

		}

	}

	&__item {

		width: 30%;
		text-align: center;
		margin: 50px 0;
		img{
			margin: 0 auto 38px;
		}

		@media #{$mdWidth} {

			width: 40%;

		}

		@media #{$smWidth} {

			width: 100%;
			// margin: 18px auto;

		}

	}

	&__ico {

		width: 72px;
		height: 72px;
		border-radius: 50%;
		margin: 30px auto 23px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba(250,208,106,.1);

		svg {

			fill: var(--yellow);

		}

	}

	&__title {

		margin: 23px 0 12px;

	}

}