.ant-modal{
    &-content{
        background: linear-gradient(94.5deg, #5721B1 9.73%, #78D0E5 72%);
        border-radius: 30px;
        padding: 2px;
        overflow: hidden;
    }
    &-header, &-body{
      background: #fff;
    }
    &-header{
      padding: 16px 50px;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
    }
    &-body{
      padding: 50px;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
    }
    &-close{
      top: 19px;
      right: 25px;
      svg{
        font-size: 30px;
      }
    }
  }
  
  .ant-btn{
      &-primary{
        background: linear-gradient(94.5deg, #5721B1 9.73%, #78D0E5 72%);
        box-shadow: 3px 3px 4px rgba(229, 229, 229, 0.5);
        border-radius: 30px;
        height: 50px;
        border:none;
        font-size:18px;
        color: #fff;
      }
  }