.header {

	position: fixed;
	width: 100%;
	z-index: 11;
	.ant-radio-group.wallet{
		border-radius: 25px;
		box-shadow: 3px 3px 4px rgba(229, 229, 229, 0.5);
		border: 1px solid #F1F1F1;
		background: #fff;
		line-height: normal;
		.ant-radio-button-wrapper{
			height: 40px;
			line-height: 40px;
			padding:  10px;
			border-radius: 25px;
			width: 60px;
			text-align: center;
			border:none;
			svg{
				margin: 0 auto;	
				fill: #888;
			}
			img{
				height: 22px;
				margin: 0 auto;
			}
			&:not(:first-child)::before{
				display: none;
			}
			&:not(.ant-radio-button-wrapper-disabled):focus-within{
				box-shadow: none;
			}

			&:first-of-type{
				margin-right: -8px;
			}
			&:last-of-type{
				margin-left: -8px;
			}
		}
		.ant-radio-button-wrapper-checked{
			background: linear-gradient(118.16deg, #5721B1 13.58%, #78D0E5 82.32%);
			svg{
				fill: #fff;
			}
		}
	}
	.center {
		width: 100%;
	}

	.ant-dropdown-trigger{
		position: relative;
		width: 130px;
		height: 40px;
	}
	.anticon-caret-down{
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
	}
	.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
		outline:none;
		border:none;
		box-shadow: none;
	}
	.ant-select{
		border: 1px solid #F1F1F1;
		box-shadow: 3px 3px 4px rgba(229, 229, 229, 0.5);
		height: 40px;
		border-radius: 20px;
		&-open{
			opacity: 1;
		}
		&:not(.ant-select-customize-input) .ant-select-selector{
			border: none;
			height: 40px;
			background:none;
		}
		&-selection-item{
			line-height: 38px!important;
			img{
				display: inline-block;
				margin-right: 5px;
			}
		}
	}

	&-dropdown{
		background: none;
		.ant-dropdown-menu-item{
			text-align: center;
			&:first-of-type{
				a{
					color: #43BDD1;
				}
			}
			&:nth-last-of-type(2){
				a{
				color: #4D4DFF;
				}
			}
			&:last-of-type{
				a{
					color: #71B1EE;
				}

			}
		}
	}
	&--bb0 {

		border-bottom-color: transparent;

	}
    
    &.menu-show {
        border-bottom: transparent;
        
        .header__menu {
			display: block;
        }
	}

    &-account {
        display: flex;
        justify-content: inherit;
        align-items: center;
        border-radius: 100px;
        position: relative;
        transition: 1s;
        width: 100%;
        height: 40px;
		background: #FFFFFF;
		border: 1px solid #F1F1F1;
		margin-left: 5px;
		box-sizing: border-box;
		color: #3F93F7;
		cursor: pointer;
		.dropdown{
			position: absolute;
			width: 170px;
			top: 45px;
			right: 0;
			padding: 10px;
			background: #FFFFFF;
			box-shadow: -3px -3px 4px rgba(229, 229, 229, 0.5), 3px 3px 4px rgba(229, 229, 229, 0.5);
			border-radius: 30px;
			text-align: center;
			padding:10px;
			li{
				a{
					font-size: 18px;
					line-height: 25px;
					color: #000;
					padding: 10px;
					margin:5px;
					display: inline-block;
					width: 100%;
					&.active, &:hover{
						background: linear-gradient(97.86deg, #5721B1 8.15%, #78D0E5 93.61%);
						border-radius: 25px;
						color: #fff;
					}
				}
				// &:hover{
				// 	background: #FFFFFF;
				// 	box-shadow: 0px -3px 4px rgba(229, 229, 229, 0.5), 0px 3px 4px rgba(229, 229, 229, 0.5);
				// 	border-radius: 30px;
				// }
			}
		}

        @media #{$mdWidth} {

            width: 150px;
            position: absolute;
            height: 44px;
            top: 10px;
            left: calc(50% - 75px);

        }

        @media (max-width: 470px) {

            // right: unset;
			// left: 20px; 
			display: none;

        }

        @media (max-width: 370px) {

            width: 240px;

        }

        .balance {
            width: 160px;
            text-align: right;

            p {
				color: #3F93F7;
                margin-right: 16px;

                @media (max-width: 370px) {

                    margin-right: 8px;
        
                }
            }
        } 

        .address {
			padding: 0 8px;
            max-width: 153px;
            width: 100%;
            height: 100%;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
			background: #fff;
			box-shadow: 0px 4px 4px rgba(229, 229, 229, 0.5);
			color: var(--primary);

            @media (max-width: 370px) {

                max-width: 120px;
    
            }
        }

        .point {
            width: 10px;
            height: 10px;
            background: var(--primary);
            border-radius: 50%;
            margin-left: 10px;

            @media (max-width: 370px) {

                display: none;
    
            }
        }
    }

	&__box {

		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 64px;
		width: 100%;
		padding: 0 80px;
		@media #{$mdWidth} {
			height: 64px!important;
		}

		@media #{$mdWidth} {

			display: block;
			height: auto;
			padding: 18px 20px;
			// background: #fff;

		}

	}

	&__logo {

		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		display: inline-block;
		svg {
			width: 115px;
			height: auto;
		}

        &--small {
            display: none; 


            &.active {
                @media (max-width: 470px) {

                    display: none; 
        
                }
            }

            @media (max-width: 580px) {

                display: block; 
    
            }
        }

	}

	&__menu {
		display: flex;
		align-items: center;

		@media #{$mdWidth} {
			background: #FFF;
			display: none;
			margin: 0 -20px;
			border-top: 1px solid #ddd;

        }
        
        &-wrapper {
            display: flex;
            align-items: center;

            @media #{$mdWidth} {

                display: block;
    
            }
        }

	}

	&__btn {


		@media #{$mdWidth} {

            display: block;
            position: absolute;
            top: 13px;
            left: calc(50% - 40px);

        }

        @media (max-width: 370px) {

            right: 75px;

        }
        .btn {
			width: 150px;
			text-align: center;
			display: flex;
			padding: 10px;
            justify-content: center;
            align-items: center;
			border: 1px solid #F1F1F1;
			box-sizing: border-box;
			box-shadow: 3px 3px 4px rgba(229, 229, 229, 0.5);
			border-radius: 15px;
            @media #{$mdWidth} {

				width: 150px;
    
            }
        }

	}

	&__lk {

		line-height: 38px;
		white-space: nowrap;
		background-color: rgba(255, 255, 255, .2);
		border-radius: 20px;
		display: flex;

		&-balance {

			padding: 0 12px;
			color: var(--color);

		}

		&-number {

			padding: 0 50px 0 18px;
			position: relative;
			background-color: #fff;
			color: var(--bg);
			border-radius: 20px;
			margin-left: 18px;
			font-size: 14px;

			&::after {

				position: absolute;
				width: 16px;
				height: 16px;
				top: 0;
				right: 16px;
				bottom: 0;
				margin: auto;
				content: '';
				border-radius: 50%;
				background-color: var(--yellow);

			}

		}

	}

}

@media #{$mdWidth} {

	.btn-menu-toggle {

		width: 48px;
		height: 48px;
		position: absolute;
		top: 10px;
		right: 8px;
		background: none;

		&::after,
		&::before {

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			width: 24px;
			height: 2px;
			transition: var(--transitionDefault);
			background-color: #000;
			content: '';

		}

		&::before {

			transform: rotate(180deg) translate3d(0, 5px, 0);

		}

		&::after {

			transform: rotate(180deg) translate3d(0, -5px, 0);

		}

		.menu-show & {

			&::before {

				transform: rotate(45deg) translate3d(0, 0px, 0);

			}

			&::after {

				transform: rotate(-45deg) translate3d(0, 0px, 0);

			}

		}

	}

	.menu-show {

		.main {

			display: none;

		}

		.header {

			flex-grow: 1;
			border-bottom: 0;

		}

		.header__menu {

			display: block !important;

		}

	}

}
.ant-dropdown{
	border: 1px solid #F1F1F1;
	background: #fff;
	box-shadow: 3px 3px 3px rgba(229, 229, 229, 0.5);
	border-radius: 15px;
	.ant-dropdown-menu{
		background: none;
		box-shadow: none;
	}
}
.ant-select-item-option-content{
	img{display: inline-block;margin-right: 5px}
}
.ant-select-dropdown{
	border: 1px solid #F1F1F1;
	background: #fff;
	box-shadow: 3px 3px 3px rgba(229, 229, 229, 0.5);
	border-radius: 15px!important;
}
.ant-select-single.ant-select-open .ant-select-selection-item{
	color: #000!important;
}