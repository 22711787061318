.visible-md,
.visible-sm,
.visible-xs {

	display: none;

}

.visible-lg {

	display: block;

}

@media #{$desktop} {


}

@media #{$mdWidth} {

	.hidden-md {

		display: none !important;

	}

	.visible-md {

		display: block;

	}

}

@media #{$smWidth} {

	.hidden-sm {

		display: none !important;

	}

	.visible-sm {

		display: block;

	}

}

@media #{$xsWidth} {

	.hidden-xs {

		display: none !important;

	}

	.visible-xs {

		display: block;

	}

}