.publish {
    width: 100%;
    height: 386px;
	background: linear-gradient(125.16deg, #6E52D5 29.34%, #7071E9 46.84%, #68B8DA 62.69%, #78D0E5 74.75%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 76px 0 24px 0;
}

.publish-artwork {
    color: #9D9D9D;;
    border-radius: 20px;

    .videoBox{
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 999;
    }

    &__content{
        display: flex;
        flex-direction: row;
        background: #fff;
        border-radius: 8px;
        padding: 20px;
        margin: 0 auto;
        @media #{$smWidth} {
            flex-direction: column;
		}
    }

    &__form{
        width: calc(100% - 400px);
        @media #{$smWidth} {
            width: 100%;
		}
    }


    &__title {
        font-weight: 400;
        text-align: center;
        font-size: 42px;
        line-height: 24px;
        justify-content: center;
        display: flex;
        align-items: center;
        margin-bottom: 75px;
        color: #FFFFFF;
    }

    &__buy {
        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media #{$smWidth} {
                display: block;
            }

            &>div {
                display: flex;
                align-items: center;

                &:first-child {
                    @media #{$smWidth} {
                        margin-bottom: 16px;
                    }
                }
            }

            svg {
                margin-right: 16px;
            }
        }

        &-caption {
            margin-left: 24px;
            color: rgba(#fff, 0.8);
        }
    }

    &__inputbox {
        position: relative;
        margin: 20px 0;
        background-color: #fff;
        border-radius: 8px;

        @media #{$smWidth} {
            margin: 24px 0;
        }
    }

    &__input {
        font-weight: 400;
        border: 1px solid #F1F1F1;
        box-shadow: 5px 5px 5px rgba(229, 229, 229, 0.5);
        border-radius: 25px;

        &:hover {
            border-color: var(--primary);
        }

        @media ($desktop) {
            &:hover {
                border-color: var(--primary);
            }
        }

        &:focus {
            border-color: var(--primary);
        }
    }

    &__label {
        display: block;
        font-weight: 500;
        font-size: 16px;
        line-height: 27px;
        margin: 12px 0;
        color: #000;
    }

    &__drop {
        position: relative;
        min-height: 153px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        overflow: hidden;
        width: 400px;
        height: 400px;
        margin: 0 20px 0 0;

        &:hover {
            opacity: 0.8;
        }

        @media ($desktop) {
            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }

        &-input {
            position: absolute;
            top: -10px;
            left: -10px;
            cursor: pointer;
            opacity: 0;
            font-size: 999px;
            z-index: 1000;
        }

        &-content {
            pointer-events: none;
            width: 100%;
            height: 100%;
            background: #c4c4c4;
            display: flex;
            justify-content: center;
            .cover {
                max-height: 500px;
                width: 100%;
                object-fit: contain !important;
            }
        }

        &-logo {
            margin: 12px auto;
            fill: var(--yellow);
            stroke: #000;
        }

        &-text {
            font-size: 14px;
            line-height: 21px;
            color: #888;
            margin: auto 40px;
            text-align: left;
            word-break: break-word;
        }

        &-format {
            font-size: 12px;
            line-height: 18px;
            color: #888;
            margin: 20px 20px 20px 0;
            display: block;
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;

        @media #{$smWidth} {
            display: block;
            margin: 0;
        }

        &-item {
            width: 100%;

            @media #{$smWidth} {
                width: 100%;
                margin: 24px 0;
            }
        }
    }

    &__row &__inputbox {
        margin: 0;

        @media #{$smWidth} {
            margin: 24px 0;
        }
    }

    &__text-error {
        font-size: 14px;
        color: var(--error);
        position: relative;
        top: 12px;

        @media #{$smWidth} {
            margin: 12px 0 24px;
            top: 0;
        }
    }

    &__note {
        font-size: 16px;
        line-height: 27px;
        padding-left: 12px;
        margin: 36px 0;
        border-left: 4px solid var(--yellow);

        @media #{$smWidth} {
            margin: 24px 0;
        }
    }

    &__btn {
        width: 192px;
        margin: 36px auto;
        box-sizing: border-box;
        height: 50px;
        color: #fff;
        background: linear-gradient(94.5deg, #5721B1 9.73%, #78D0E5 72%);
        box-shadow: 3px 3px 4px #E5E5E5;
        border-radius: 30px;

        @media #{$smWidth} {
            margin: 24px auto 48px;
        }
    }
}
