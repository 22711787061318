.wrapper__container{
    width: 100%;
    color: #000;
    aside{
        margin-bottom: 80px;
    }
    // @media #{$mdWidth} {
    //     top: 64px;
    // }
    h2{
        font-size: 35px;
        line-height: 40px;
        margin: 0 auto 10px;
        @media #{$smWidth} {
            font-size: 24px;
		}
    }
    .h4{
        font-size: 24px;
        @media #{$smWidth} {
            font-size: 18px;
		} 
    }
    p{
        color: #888;
    }
    .title{
        text-align: center;
        margin-bottom: 40px;
        padding: 0 20px;
        img{
            display: inline-block;
            position: relative;
            top: 10px;
        }
    }
    footer{
        text-align: center;
        margin: 0 20px 80px;
        color:#737373;
    }
}